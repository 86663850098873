<template>
  <ClientOnly>
    <div class="product-wholesale-price-section">
      <table class="">
        <tbody class="divide-y divide-gray-200">

        <!-- Perakende Satış Fiyatı -->
        <template v-if="getPriceListPrice('Z')">
          <tr>
            <th scope="col" class="text-primary p-2 text-left text-base font-medium">
              {{t('perakende-satis-fiyati')}}
            </th>
            <td class="p-2 text-right text-base font-bold space-y-1">
              <PriceUpdateModeDescriptionForB2B>
                <div class="text-lg">
                  {{ formatPrice(getPriceListPrice('Z'), selectedVariant?.primaryStockData?.currency) }}
                </div>
                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
              </PriceUpdateModeDescriptionForB2B>
            </td>
          </tr>
        </template>

        <!-- Tavsiye Satış Fiyatı -->
        <template v-if="getPriceListPrice('Y')">
          <tr>
            <th scope="col" class="text-primary p-2 text-left text-base font-medium">
              {{t('tavsiye-fiyati')}}
            </th>
            <td class="p-2 text-right text-base font-bold space-y-1">
              <PriceUpdateModeDescriptionForB2B>
                <div class="text-lg">
                  {{ formatPrice(getPriceListPrice('Y'), selectedVariant?.primaryStockData?.currency) }}
                </div>
                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
              </PriceUpdateModeDescriptionForB2B>
            </td>
          </tr>
        </template>

        <!-- Toptan Fiyatı Bitiş -->
        <template v-if="getPriceListPrice('X')">
          <tr>
            <th scope="col" class="text-primary p-2 text-left text-base font-medium">
              {{t('adet-fiyati')}}
            </th>
            <td class="p-2 text-right text-base font-bold space-y-1 text-secondary">
              <PriceUpdateModeDescriptionForB2B>
                <div class="text-lg">
                  {{ formatPrice(getPriceListPrice('X'), selectedVariant?.primaryStockData?.currency) }}
                </div>
                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
              </PriceUpdateModeDescriptionForB2B>
            </td>
          </tr>
        </template>

        </tbody>
      </table>
    </div>
  </ClientOnly>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { wholesaleConfig } = storeToRefs(storeConfig)

const {isVatExcludeForB2B} = useHelper()
const onlyShowQty = ref(false)

const { t } = useI18n({
  useScope: 'local'
})

const {
  selectedVariant,
  product,
  discountPriceExists,
  getPriceListPrice
} = await useProduct()

const {
  showListPrice,
  showListPriceAndCampaignTogether
} = useListing()

const strikethroughPrice = computed(() => unref(selectedVariant)?.primaryStockData?.discountPrice ? unref(selectedVariant)?.primaryStockData?.discountPrice : unref(selectedVariant)?.primaryStockData?.listPrice)

</script>

<i18n lang="json">
{
  "tr": {
    "perakende-satis-fiyati" : "Perakende Satış Fiyatı",
    "tavsiye-fiyati" : "Tavsiye Edilen Satış Fiyatı",
    "adet-fiyati" : "Adet Fiyatı"
  },
  "en": {
    "perakende-satis-fiyati" : "Retail Price",
    "tavsiye-fiyati" : "Recommended Retail Price",
    "adet-fiyati" : "Unit Price"
  }
}
</i18n>